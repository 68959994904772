<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card>
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn header-row-in-v-select"
               :style="{backgroundColor: $store.getters.cardTitle}"
          >
            <b-col cols="12">
              <h4 class="text-white d-inline-block" v-if="!filtre"> Yılı Konuk ve Tarife Raporu (Sadece
                Konuk Temelli Rapor)</h4>
              <h4 class="text-white d-inline-block" v-if="filtre">{{ year }} Yılı Konuk ve Tarife Raporu
                (Sadece Konuk Temelli
                Rapor)</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-print="'#printMe'"
                  variant="bgInfo"
                  class="border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                Listeyi Yazdır
              </b-button>
            </b-col>
          </div>
        </b-row>
        <hr class="mt-4 mb-1">
        <b-row>
          <b-col cols="12">
            <v-select
                @input="changeYear"
                v-model="year"
                :options="years"
                class="mr-1 float-right"
                :class="width < 821? 'mt-1':width < 540 ?'mt-2':''"
                :style="{width : width > 1600 ?'15%' : width < 1600 ? '30%':''}"
            />
          </b-col>
        </b-row>
        <div id="printMe">
          <div
              v-for="(tarife, index) in tarifeGet"
              :key="index"
          >
            <strong>{{ tarife.tarifeAdi }} Konaklama</strong>
            <table class="table table-borderless mb-2 mt-1" :class="width <700 ?'table-responsive':''">
              <thead>
              <tr>
                <th
                    v-for="(item, index) in ay"
                    :key="index"
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                >
                  {{ item }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td
                    v-for="(item, index) in 12"
                    :key="index"
                >
                  <!-- {{ yillikRaporReturn[item].liste[[tarife.tarifeID]] }} -->
                  {{ yillikReturn(item, tarife.tarifeID) }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div>
            <strong>Toplam Konaklama</strong>
            <table class="table table-borderless mb-2 mt-1" :class="width <700 ?'table-responsive':''">
              <thead>
              <tr>
                <th
                    v-for="(item, index) in ay"
                    :key="index"
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                >
                  {{ item }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td
                    v-for="(item, index) in ay"
                    :key="index"
                >
                  <p href="javascript:void(0)">
                    {{ toplam(index + 1) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td
                    v-for="(item, index) in ay"
                    :key="index"
                >
                  <p>%{{ doluluk(index + 1) }}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <b-row class="justify-content-end">
            <div class="d-grid justify-content-end">
              <hr>
              <h5
                  v-for="(tarife, index) in tarifeGet"
                  :key="index"
              >
                {{ yillikRaporReturn.ozet[tarife.tarifeID] || 0 }}
                {{ tarife.tarifeAdi }} Konuk Kaydı Bulundu
              </h5>
              <h4>Toplam {{ toplamKonaklamaGet }} Konuk Kaydı Bulundu</h4>
              <hr>
            </div>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      years: [2019, 2020, 2021, 2022, 2023, 2024, 2025],
      ay: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      year: null,
      filtre: false,
    }
  },
  computed: {
    toplamKonaklamaGet() {
      if (this.yillikRaporReturn.ozet) {
        return this.yillikRaporReturn.ozet.toplamKonaklama
      }
    },
    yillikRaporReturn() {
      return this.$store.getters.yillikRaporReturn
    },
    tarifeGet() {
      return Object.values(this.$store.getters.tarife)
    },
    konaklama() {
      return (salut = '', tur) => this.$store.getters.konaklamaGet(salut, tur)
    },
  },
  beforeDestroy() {
    this.$store.commit('clearYillikRapor')
  },
  created() {
    const today = new Date()
    this.year = today.getFullYear()
    this.filtre = true
    this.$store
        .dispatch('yillikRapor', {
          year: this.year,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    changeYear(newVal) {
      this.spinner = true
      this.$store.dispatch('yillikRapor', {
        year: newVal,
      })
          .finally(() => this.spinner = false)
    },
    doluluk(i) {
      if (this.yillikRaporReturn[i]) {
        return this.yillikRaporReturn[i].dolulukOrani
      }
    },
    toplam(i) {
      if (this.yillikRaporReturn[i]) {
        if (this.yillikRaporReturn[i].liste) {
          return this.yillikRaporReturn[i].liste.toplam
        }
      }
    },
    yillikReturn(i, id) {
      if (this.yillikRaporReturn[i]) {
        if (this.yillikRaporReturn[i].liste) {
          return this.yillikRaporReturn[i].liste[id]
        }
      }
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.header-row-in-v-select .vs--single .vs__dropdown-toggle {
  padding-left: 6px;
  border: none !important;
  height: 30px;
  transform: translate(0px, -2px);
}

table {
  border: 1px solid grey !important;
}
</style>
<style scoped>
p {
  padding: 0 !important;
  margin: 0 !important;
}

table {
  border: 1px solid grey !important;
}

.table th,
.table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

tr {
  text-align: center;
}

</style>
